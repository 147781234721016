import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { v4 as uuidv4 } from 'uuid';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import axios from './axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { set } from 'rsuite/esm/internals/utils/date';
import './Functions/manage_orders.css';
import { useNavigate } from 'react-router-dom';

const handlePagination = (page, totalPages, setCurrentPage)  => {
    if (page < 1 || page > totalPages) {
      return;
    }
    setCurrentPage(page);
};


const renderPagination = ({ totalPages, currentPage, setCurrentPage}) => {
    if (totalPages >= 8) {
        return null; // No need for advanced pagination if there are 5 or fewer pages
    }
    const items = [];
    items.push(<Pagination.Prev key={uuidv4()} onClick={() => handlePagination(Number(currentPage) - 1, totalPages, setCurrentPage)} disabled={currentPage === 1}/>)

    for (let number = 1; number <= totalPages; number++) {
    items.push(
        <Pagination.Item key={uuidv4()} active={number === Number(currentPage)} onClick={() => handlePagination(number, totalPages, setCurrentPage)}>
        {number}
        </Pagination.Item>
    );
    }

    items.push(<Pagination.Next key={uuidv4()} onClick={() => handlePagination(Number(currentPage) + 1, totalPages, setCurrentPage)} disabled={Number(currentPage)== Number(totalPages)} />)

    return <Pagination size="sm">{items}</Pagination>;
};

const renderTableData = (currentPage,data, ...fields) => {
    return data.map((entry, index) => (
        <tr key={uuidv4()}>
            <td>{(currentPage-1)*20+index+1}</td>
            {fields.map((field, i) => (
                <td key={uuidv4()}>{entry[field]}</td>
            ))}
        </tr>
    ));
};

const PartyTable = ({ currentPage, data, handleDeleteParty, fields }) => {
    const navigate = useNavigate();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedParty, setSelectedParty] = useState(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [partyData, setPartyData] = useState(null);

    const openDeleteModal = (entry) => {
        setSelectedParty(entry);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedParty(null);
    };

    const confirmDelete = () => {
        handleDeleteParty(selectedParty);
        closeDeleteModal();
    };

    const handleView = async (partyID) => {
        try {
            const response = await axios.get(`${window.backendUrl}/get_party_info/${partyID}`);
            setPartyData(response.data);
            setIsViewModalOpen(true);
        } catch (error) {
            console.error('Error fetching party data:', error);
        }
    };

    const closeViewModal = () => {
        setIsViewModalOpen(false);
        setPartyData(null);
    };


    const handlePartyEditClick = (partyId) => {
      navigate('/add-new-party', { state: {partyId } });
    };

    return (
        <>
            {data.map((entry, index) => (
                <tr key={uuidv4()}>
                    <td>{(currentPage - 1) * 20 + index + 1}</td>
                    {fields.map((field) => (
                        <td key={uuidv4()}>{entry[field]}</td>
                    ))}
                    <td style={{textAlign:'center'}}>
                        <Image onClick={() => handleView(entry['PartyID'])} src="/Images/info_logo.jpg" alt="info" style={{ width: '17px', height: '17px', marginRight: '15px', cursor: 'pointer' }} />
                        <i class="fa fa-edit" id="edit_icon_party" style={{marginRight: '15px'}}  onClick={() => handlePartyEditClick(entry['PartyID'])}></i>
                        <Image onClick={() => openDeleteModal(entry)} src="/Images/dustbin_logo.jpg" alt="dustbin" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                    </td>
                </tr>
            ))}

            <Modal show={isDeleteModalOpen} onHide={closeDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this party?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteModal}>No</Button>
                    <Button variant="danger" onClick={confirmDelete}>Yes</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isViewModalOpen} onHide={closeViewModal} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Party Information</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {partyData ? (
                        <>
                            <Table bordered>
                                <tbody>
                                    <tr>
                                        <td><strong>Name:</strong></td>
                                        <td>{partyData.PartyName}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Contact No.:</strong></td>
                                        <td>{partyData.ContactNo}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Address:</strong></td>
                                        <td>{partyData.Address}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Team:</strong></td>
                                        <td>{partyData.TeamName}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Currency:</strong></td>
                                        <td>{partyData.Currency}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Payment Type:</strong></td>
                                        <td>{partyData.PaymentType}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Allowance:</strong></td>
                                        <td>
                                            L+W: {partyData.AllowanceLW}, W+H: {partyData.AllowanceWH}, Length: {partyData.AllowanceLength}, Width: {partyData.AllowanceWidth}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Notes:</strong></td>
                                        <td>{partyData.Notes}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <h5>Items</h5>
                            {partyData.items.map(item => (
                                <div key={item.PartyItemID} style={{ marginBottom: '20px' }}>
                                    <h6>{item.ItemName}</h6>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Specification</th>
                                                <th>Grade</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.specifications.map((spec, specIndex) => (
                                                spec.grades.map((grade, gradeIndex) => (
                                                    <tr key={grade.PartyGradeID}>
                                                        {gradeIndex === 0 && (
                                                            <td rowSpan={spec.grades.length}>{spec.Specification}</td>
                                                        )}
                                                        <td>{grade.Grade}</td>
                                                        <td>{grade.Price}</td>
                                                    </tr>
                                                ))
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            ))}
                        </>
                    ) : (
                        <p>Loading...</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeViewModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PartyTable;

const renderTableData2 = (currentPage, data, onCellClick, ...fields) => {
    return data.map((entry, index) => (
        <tr key={uuidv4()}>
                <td key={uuidv4()} onClick={() => onCellClick(entry)} style={{ cursor: 'pointer' }}>
                    <span style={{ textDecoration: 'none', transition: 'text-decoration 0.2s' }} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>
                        {(currentPage - 1) * 20 + index + 1}
                    </span>
                </td>
            {fields.map((field, i) => (
                <td key={uuidv4()} onClick={() => onCellClick(entry)} style={{ cursor: 'pointer' }}>
                    <span style={{ textDecoration: 'none', transition: 'text-decoration 0.2s' }} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>
                        {entry[field]}
                    </span>
                </td>
            ))}
        </tr>
    ));
};

const renderAdvancedPagination=({ totalPages, currentPage, setCurrentPage })=>{
    if(totalPages < 8){
    return null;
    }
    return(
    <Pagination size="sm" style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
        <Pagination.Prev  onClick={() => handlePagination(Number(currentPage) - 1,totalPages, setCurrentPage)} disabled={Number(currentPage)== 1}  key="prev"/>
        {(currentPage == 1 || currentPage == 2 || currentPage == totalPages || currentPage == totalPages - 1) && (
            <>
            <Pagination.Item key={uuidv4()} active={1 === Number(currentPage)} onClick={() => handlePagination(1,totalPages, setCurrentPage)}>1</Pagination.Item>
            <Pagination.Item key={uuidv4()} active={2 === Number(currentPage)} onClick={() => handlePagination(2,totalPages, setCurrentPage)}>2</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item key={uuidv4()} active={(totalPages - 1) === Number(currentPage)} onClick={() => handlePagination(totalPages - 1,totalPages, setCurrentPage)}>{totalPages - 1}</Pagination.Item>
            <Pagination.Item style={{marginRight:'16px'}} key={uuidv4()} active={totalPages === Number(currentPage)} onClick={() => handlePagination(totalPages,totalPages, setCurrentPage)}>{totalPages}</Pagination.Item>
            </>
        )}
        {(currentPage == totalPages - 2) && (
            <>
            <Pagination.Item key={uuidv4()} active={1 === Number(currentPage)} onClick={() => handlePagination(1,totalPages, setCurrentPage)}>1</Pagination.Item>
            <Pagination.Item key={uuidv4()} active={2 === Number(currentPage)} onClick={() => handlePagination(2,totalPages, setCurrentPage)}>2</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item key={uuidv4()} active={(totalPages - 2) === Number(currentPage)} onClick={() => handlePagination(totalPages - 2,totalPages, setCurrentPage)}>{totalPages - 2}</Pagination.Item>
            <Pagination.Item key={uuidv4()} active={(totalPages - 1) === Number(currentPage)} onClick={() => handlePagination(totalPages - 1,totalPages, setCurrentPage)}>{totalPages - 1}</Pagination.Item>
            <Pagination.Item style={{marginRight:'16px'}} key={uuidv4()} active={totalPages === Number(currentPage)} onClick={() => handlePagination(totalPages,totalPages, setCurrentPage)}>{totalPages}</Pagination.Item>
            </>
        )}
        {currentPage == 3 && (
            <>
            <Pagination.Item key={uuidv4()} active={1 === Number(currentPage)} onClick={() => handlePagination(1,totalPages, setCurrentPage)}>1</Pagination.Item>
            <Pagination.Item key={uuidv4()} active={2 === Number(currentPage)} onClick={() => handlePagination(2,totalPages, setCurrentPage)}>2</Pagination.Item>
            <Pagination.Item key={uuidv4()} active={3 === Number(currentPage)} onClick={() => handlePagination(3,totalPages, setCurrentPage)}>3</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item key={uuidv4()} active={(totalPages - 1) === Number(currentPage)} onClick={() => handlePagination(totalPages - 1,totalPages, setCurrentPage)}>{totalPages - 1}</Pagination.Item>
            <Pagination.Item style={{marginRight:'16px'}} key={uuidv4()} active={totalPages === Number(currentPage)} onClick={() => handlePagination(totalPages,totalPages, setCurrentPage)}>{totalPages}</Pagination.Item>
            </>
        )}
        {currentPage > 3 && currentPage < totalPages - 2 && (
            <>
            <Pagination.Item key={uuidv4()} active={1 === Number(currentPage)} onClick={() => handlePagination(1,totalPages, setCurrentPage)}>1</Pagination.Item>
            <Pagination.Item key={uuidv4()} active={2 === Number(currentPage)} onClick={() => handlePagination(2,totalPages, setCurrentPage)}>2</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item key={uuidv4()} active={true} onClick={() => handlePagination(currentPage,totalPages, setCurrentPage)}>{currentPage}</Pagination.Item>
            <Pagination.Item key={uuidv4()} active={false} onClick={() => handlePagination(Number(currentPage)+1,totalPages, setCurrentPage)}>{Number(currentPage) + 1}</Pagination.Item>
            <Pagination.Ellipsis />
            <Pagination.Item key={uuidv4()} active={(totalPages - 1) === Number(currentPage)} onClick={() => handlePagination(totalPages - 1,totalPages, setCurrentPage)}>{totalPages - 1}</Pagination.Item>
            <Pagination.Item style={{marginRight:'16px'}}key={uuidv4()} active={totalPages === Number(currentPage)} onClick={() => handlePagination(totalPages,totalPages, setCurrentPage)}>{totalPages}</Pagination.Item>
            </>
        )}
        <Pagination.Next style={{ marginLeft:'8px',position: 'absolute', right: 0 }}  onClick={() => handlePagination(Number(currentPage) + 1,totalPages, setCurrentPage)} disabled={Number(currentPage)== Number(totalPages)}  key={uuidv4()}/>
    </Pagination>
    )
}

const JobApprovalModal = ({ order_status, show, handleClose, handleConfirm }) => {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {order_status === 'APPROVED' ? 'Are you sure you want to Disapprove this job?' :
             'Are you sure you want to Approve this job?'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
                {order_status === 'APPROVED' ? 'Disapprove' : 'Approve'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const ScheduleDateModal = ({ show, handleClose, handleSave }) => {
    const [date, setDate] = useState('');

    useEffect(() => {
      setDate('');
    },[]);

    const onSave = () => {
      const schedule_date_unix = Math.floor(new Date(date).getTime() / 1000);
      handleSave(schedule_date_unix);
    };

    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Schedule Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="scheduleDate">
              <Form.Label> <h6>Select a date:</h6></Form.Label>
              {/* <Form.Control 
                type="date" 
                onChange={(e) => set_schedule_date(e.target.value)} 
              /> */}
              {/* <Form.Control 
                type="date" 
                onChange={(e) => set_schedule_date(Math.floor(new Date(e.target.value).getTime() / 1000))}
              /> */}
              <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const Add_BM_Modal = ({ show, setShow, handleSave, details }) => {
    const [date, setDate] = useState('');
    const [new_board_making_qty, set_new_board_making_qty] = useState(0);
  
    const onSave = () => {
        const today = new Date();
        const selectedDate = new Date(date + 'T00:00:00+06:00');
        const isToday = selectedDate.toDateString() === today.toDateString();
        const unixDate = isToday ? Math.floor(today.getTime() / 1000) : Math.floor(selectedDate.getTime() / 1000);

        handleSave({ board_making_date: unixDate, new_board_making_qty});
        setShow(false);
    };

    useEffect(() => {
        set_new_board_making_qty(details.pp_qty - details.board_making_qty);
        const formattedDate = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`;
        setDate(formattedDate);
    }, [show]);

    // console.log(number)
    return (
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add new board making quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Row >
            <Col className='d-flex-col me-2'>
                <h6 style={{textAlign:'right', marginBottom:'20px'}}>PP quantity : </h6>
                <h6 style={{textAlign:'right', marginBottom:'20px'}}>Board making done quantity : </h6>
                <h6 style={{textAlign:'right', marginBottom:'40px'}}>Board making date : </h6>
                <h6 style={{textAlign:'right'}}>New board making quantity : </h6>
            </Col>
            <Col className='d-flex-col'>
                <h6 style={{textAlign:'left', marginBottom:'20px'}}>{details.pp_qty}</h6>
                <h6 style={{textAlign:'left', marginBottom:'20px'}}>{details.board_making_qty}</h6>
                <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} style={{width:'150px',marginBottom:'20px'}}/>
                <Form.Control type="number" value={new_board_making_qty} style={{ width: '150px' }}
                    onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        set_new_board_making_qty(isNaN(value) ? 0 : value);
                    }}
                />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const Edit_PP_Qty_Modal = ({ show, setShow, handleSave, details }) => {
    const [updated_pp_qty, set_updated_pp_qty] = useState(0);
  
    const onSave = () => {
        handleSave(updated_pp_qty);
        setShow(false);
    };

    useEffect(() => {   
        set_updated_pp_qty(0);
    }, [show]);

    return (
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update production planning quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Row >
            <Col className='d-flex-col me-2'>
                <h6 style={{textAlign:'right', marginBottom:'20px'}}>Current PP quantity : </h6>
                <h6 style={{textAlign:'right', marginBottom:'40px'}}>Updated PP quantity : </h6>
                <h6 style={{textAlign:'right'}}>Board making done quantity : </h6>
            </Col>
            <Col className='d-flex-col'>
                <h6 style={{textAlign:'left', marginBottom:'20px'}}>{details.pp_qty}</h6>
                <Form.Control type="number" value={updated_pp_qty} style={{ width: '150px', marginBottom:'25px' }}
                    onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        set_updated_pp_qty(isNaN(value) ? 0 : value);
                    }}
                />
                <h6 style={{textAlign:'left', marginBottom:'20px'}}>{details.board_making_qty}</h6>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const PP_Date_Modal = ({ show, setShow, handleSave, details }) => {
    const [date, setDate] = useState('');
  
    const onSave = () => {
        if (!date){
            return
        }
        const unixDate=Math.floor(new Date(date).getTime() / 1000);
        handleSave({ updated_pp_date: unixDate, all_pp_ids: details.all_pp_ids });

        setShow(false);
    };

    useEffect(() => {
        setDate('')
    }, [show]);

    return (
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change production planning date</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Row >
            <Col className='d-flex-col me-2'>
                <h6 style={{textAlign:'right', marginBottom:'40px'}}>Current PP date : </h6>
                <h6 style={{textAlign:'right'}}>Updated PP date : </h6>
            </Col>
            <Col className='d-flex-col'>
                <h6 style={{textAlign:'left', marginBottom:'40px'}}>{details.pp_date}</h6>
                <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} style={{width:'150px',marginBottom:'20px'}}/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const BM_Small_Trash_Modal = ({ show, setShow, handleSave, details }) => {

  const onSave = () => {
      handleSave(details.ProductionPlanningId);
      setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title><strong>Remove production planning permanently</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <h5>Are you sure you want to undo the <strong>{details.pp_qty - details.board_making_qty}</strong> remaining balance quantity?</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const Add_Finishing_Modal = ({ show, setShow, handleSave, details }) => {
    const [date, setDate] = useState('');
    const [new_finishing_qty, set_new_finishing_qty] = useState(0);
  
    const onSave = () => {
        const today = new Date();
        const selectedDate = new Date(date + 'T00:00:00+06:00');
        const isToday = selectedDate.toDateString() === today.toDateString();
        const unixDate = isToday ? Math.floor(today.getTime() / 1000) : Math.floor(selectedDate.getTime() / 1000);

        handleSave({ finishing_date: unixDate, new_finishing_qty});
        setShow(false);
    };

    useEffect(() => {
        set_new_finishing_qty(details.board_making_qty - details.finished_qty);
        const formattedDate = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`;
        setDate(formattedDate);
    }, [show]);

    // console.log(number)
    return (
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add new finishing quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Row >
            <Col className='d-flex-col me-2'>
                <h6 style={{textAlign:'right', marginBottom:'20px'}}>PP quantity : </h6>
                <h6 style={{textAlign:'right', marginBottom:'20px'}}>Board making done quantity : </h6>
                <h6 style={{textAlign:'right', marginBottom:'25px'}}>Finishing done quantity : </h6>
                <h6 style={{textAlign:'right', marginBottom:'40px'}}>Finishing date : </h6>
                <h6 style={{textAlign:'right'}}>New finishing quantity : </h6>
            </Col>
            <Col className='d-flex-col'>
                <h6 style={{textAlign:'left', marginBottom:'20px'}}>{details.pp_qty}</h6>
                <h6 style={{textAlign:'left', marginBottom:'20px'}}>{details.board_making_qty}</h6>
                <h6 style={{textAlign:'left', marginBottom:'20px'}}>{details.finished_qty}</h6>
                <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} style={{width:'150px',marginBottom:'20px'}}/>
                <Form.Control type="number" value={new_finishing_qty} style={{ width: '150px' }}
                    onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        set_new_finishing_qty(isNaN(value) ? 0 : value);
                    }}
                />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const Gate_Pass_Date_Modal = ({ show, setShow, handleSave, details }) => {
  const [date, setDate] = useState('');

  const onSave = () => {
      if (!date){
          return
      }
      const unixDate=Math.floor(new Date(date).getTime() / 1000);
      handleSave({ updated_gate_pass_date: unixDate, gate_pass_id: details.gate_pass_id });
      setShow(false);
  };

  useEffect(() => {
      setDate('')
  }, [show]);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title><strong>Change Date</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Row >
          <Col className='d-flex-col me-2'>
              <h6 style={{textAlign:'right', marginBottom:'40px'}}>Current date : </h6>
              <h6 style={{textAlign:'right'}}>Updated date : </h6>
          </Col>
          <Col className='d-flex-col'>
              <h6 style={{textAlign:'left', marginBottom:'40px'}}>{new Date(details.gate_pass_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}</h6>
              <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} style={{width:'150px',marginBottom:'20px'}}/>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Gate_Pass_Delete_Modal = ({ show, setShow, handleDelete, details }) => {

  const onDelete = () => {
      handleDelete({gate_pass_id: details.gate_pass_id});
      setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title><strong>Remove Gate Pass Permanently</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Row >
          <Col className='d-flex-col me-2'>
              <h6>Are you sure you want to remove this gate pass ?</h6>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={onDelete}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Gate_Pass_Status_Modal = ({ show, setShow, handleSave, details }) => {
  const title= details.current_status == 0 ? 'Received by Factory' : 'Received by Office' ;
  const new_status = details.current_status == 0 ? 1 : 2;

  const onSave = () => {
      const unixDate = Math.floor(new Date().getTime() / 1000);
      handleSave({ status_modification_date: unixDate, gate_pass_list_id: details.gate_pass_list_id, new_status});
      setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title><strong>{title}</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Row >
          <Col className='d-flex-col me-2'>
              <h6>Are you sure you received this challan ?</h6>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={onSave}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const New_Gate_Pass_Modal = ({ show, setShow, handleSave}) => {
  
    const [vehicle_no, set_vehicle_no] = useState('');
    const [driver_name, set_driver_name] = useState('');

    const onSave = () => {
        handleSave({vehicle_no, driver_name});
        setShow(false);
    };

    useEffect(() => {
        set_vehicle_no('');
        set_driver_name('');
    }, [show]);

    // console.log(number)
    return (
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Gate Pass</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Row >
            <Col className='d-flex-col me-2'>
                <h5 style={{textAlign:'right', marginBottom:'40px', fontWeight:'bold'}}>Vehicle No. : </h5>
                <h5 style={{textAlign:'right', marginBottom:'20px', fontWeight:'bold'}}>Driver Name : </h5>
            </Col>
            <Col className='d-flex-col'>
                <Form.Control type="text" value={vehicle_no} onChange={(e) => set_vehicle_no(e.target.value)} style={{width:'300px',marginBottom:'20px'}}/>
                <Form.Control type="text" value={driver_name} onChange={(e) => set_driver_name(e.target.value)} style={{width:'300px',marginBottom:'20px'}}/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

const Edit_Bill_Modal = ({ show, setShow, bill_id }) => {
  const [bill_info, set_bill_info] = useState(null);

  const handleSave = () => {

      axios.post(`${window.backendUrl}/update_bill_info`, bill_info)
        .then((response) => {
            // console.log(response.data);
            setShow(false);
            window.location.reload()
        })
        .catch((error) => {
            console.log(error);
        });
  };

  useEffect(() => {
      if (show) {
          fetch_bill_info();
      }
  }, [show]);

  const fetch_bill_info = () => {
      axios.get(`${window.backendUrl}/get_bill_info/${bill_id}`)
        .then((response) => {
            set_bill_info(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
  };

  const handleInputChange = (index, field, value) => {
    if (field=='Remarks'){
      value = value || ''
    }else{
      value = parseFloat(value) || 0
    }
    const updatedBillLists = bill_info.bill_lists.map((item, i) => 
      i === index ? { ...item, [field]: value} : item
    );
    set_bill_info({ ...bill_info, bill_lists: updatedBillLists });
  };

  const handleNoteChange = (e) => {
    set_bill_info({ ...bill_info, Note: e.target.value});
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered className='custom-modal' scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Change Bill Information</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{fontSize:'16px',padding:'30px 40px'}}>
        {bill_info && (
          <>
            <Row className="d-flex justify-content-between mb-2 mt-2">
              <Col className='d-flex'>
                <div className='me-4'>
                  <p className='mb-2'><strong>Party Name</strong></p>
                  <p className='mb-2'><strong>Buyer Name</strong></p>
                  <p className='mb-2'><strong>Style</strong></p>
                </div>
                <div className='me-4'>
                  <p className='mb-2'>:</p>
                  <p className='mb-2'>:</p>
                  <p className='mb-2'>:</p>
                </div>
                <div>
                  <p className='mb-2'>{bill_info.PartyName}</p>
                  <p className='mb-2'>{bill_info.BuyerName}</p>
                  <p className='mb-2'>{bill_info.Style}</p>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className='me-4'>
                  <p className='mb-2'><strong>Bill No.</strong></p>
                  <p className='mb-2'><strong>Date : </strong></p>
                  <p className='mb-2'><strong>Job Number</strong></p>
                  <p className='mb-2'><strong>LPD No.</strong></p>
                  <p className='mb-2'><strong>Currency</strong></p>
                </div>
                <div className='me-4'>
                  <p className='mb-2'>:</p>
                  <p className='mb-2'>:</p>
                  <p className='mb-2'>:</p>
                  <p className='mb-2'>:</p>
                  <p className='mb-2'>:</p>
                </div>
                <div>
                  <p className='mb-2'>BILL/{bill_info.JobNumber}</p>
                  <p className='mb-2'>{new Date(bill_info.BillDate * 1000).toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka', year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '.')}</p>
                  <p className='mb-2'>{bill_info.JobNumber}</p>
                  <p className='mb-2'>{bill_info.LpdPoNo}</p>
                  <p className='mb-2'>{bill_info.Currency == 'BDT'? '৳' : '$'}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Item Name</th>
                    <th>Specification</th>
                    <th>Measurement</th>
                    <th>Quantity</th>
                    <th>Sq. Mt.</th>
                    <th>Unit Price <br/> (৳)</th>
                    <th>Addi Work <br/> Price (৳)</th>
                    <th>Amount</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {bill_info.bill_lists.map((item, index) => {
                    let totalQuantity = bill_info.bill_lists.reduce((acc, curr) => acc + parseFloat(curr.Quantity), 0).toFixed(2);
                    let totalAmount = bill_info.bill_lists.reduce((acc, curr) => acc + (parseFloat(curr.Quantity) * parseFloat(curr.UnitPrice + curr.AddiPrice)), 0).toFixed(2);


                    return(
                      <>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ItemName}</td>
                          <td>{item.Specification}</td>
                          <td>{`${item.Length}x${item.Width}x${item.Height}`}</td>
                          <td>
                            <input
                              type="number"
                              style={{ width: '80px' }}
                              value={item.Quantity}
                              onChange={(e) => handleInputChange(index, 'Quantity', e.target.value)}
                            />
                          </td>
                          <td>{/* Calculation for Sq. Mt. if applicable */}</td>
                          <td>
                            <input
                              type="number"
                              style={{ width: '80px' }}
                              value={item.UnitPrice}
                              onChange={(e) => handleInputChange(index, 'UnitPrice', e.target.value)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              style={{ width: '80px' }}
                              value={item.AddiPrice}
                              onChange={(e) => handleInputChange(index, 'AddiPrice', e.target.value)}
                            />
                          </td>
                          <td>{(item.Quantity*(item.UnitPrice + item.AddiPrice)).toFixed(2)}</td>
                          <td>
                            <input
                              type="text"
                              style={{ width: '80px' }}
                              value={item.Remarks || ''}
                              onChange={(e) => handleInputChange(index, 'Remarks', e.target.value)}
                            />
                          </td>
                        </tr>
                        {index === bill_info.bill_lists.length - 1 && (
                          <tr>
                            <td colSpan='4' style={{textAlign:'right'}}> <strong>Grand Total : </strong></td>
                            <td>{totalQuantity}</td>
                            <td colSpan='3'></td>
                            <td>{totalAmount}</td>
                            <td></td>
                          </tr>
                        )}
                      </>
                    )})}
                </tbody>
              </Table>
            </Row>
          </>
        )}
        {bill_info && (
        <Row className='d-flex justify-content-center mt-3'>
          <Col md='auto'>
            <p>Note: </p>
          </Col>
            <textarea rows='3' style={{width:'500px'}} value={bill_info.Note ? bill_info.Note : ''} onChange={handleNoteChange}/>
        </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Edit_Pi_Modal = ({ show, setShow, pi_id }) => {
  const [pi_info, set_pi_info] = useState(null);

  const handleSave = () => {

      axios.post(`${window.backendUrl}/update_pi_info`, pi_info)
        .then((response) => {
            setShow(false);
            window.location.reload()
        })
        .catch((error) => {
            console.log(error);
        });
  };

  useEffect(() => {
      if (show) {
          fetch_pi_info();
      }
  }, [show]);

  const fetch_pi_info = () => {
      axios.get(`${window.backendUrl}/get_pi_info/${pi_id}`)
        .then((response) => {
            set_pi_info(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
  };

  const handleInputChange = (index, field, value) => {
    if (field=='Remarks'){
      value = value || ''
    }else{
      value = parseFloat(value) || 0
    }
    console.log(`Field: ${field}, Value: ${value}`);
    const updatedPiLists = pi_info.pi_lists.map((item, i) => 
      i === index ? { ...item, [field]: value} : item
    );
    set_pi_info({ ...pi_info, pi_lists: updatedPiLists });
  };

  const handleNoteChange = (e) => {
    set_pi_info({ ...pi_info, Note: e.target.value});
  };

  const handlePartyAddressChange = (e) => {
    set_pi_info({ ...pi_info, PartyAddress: e.target.value});
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered className='custom-modal' scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Change PI Information</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{fontSize:'16px',padding:'30px 40px'}}>
        {pi_info && (
          <>
            <Row className="d-flex justify-content-between mb-2 mt-2">
              <Col className='d-flex'>
                <div className='me-4'>
                  <p ><strong>Party Name</strong></p>
                  <p style={{marginBottom:'40px'}}><strong>Party Address</strong></p>
                  <p ><strong>Buyer Name</strong></p>
                  <p><strong>Style</strong></p>
                </div>
                <div className='me-4'>
                  <p >:</p>
                  <p style={{marginBottom:'40px'}}>:</p>
                  <p >:</p>
                  <p >:</p>
                </div>
                <div>
                  <p className='mb-2'>{pi_info.PartyName}</p>
                  <textarea rows='2' style={{width:'270px',marginBottom:'5px'}} value={pi_info.PartyAddress ? pi_info.PartyAddress : ''} onChange={handlePartyAddressChange}/>
                  <p >{pi_info.BuyerName}</p>
                  <p className='mb-2'>{pi_info.Style}</p>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className='me-4'>
                  <p ><strong>Pi No.</strong></p>
                  <p ><strong>Date : </strong></p>
                  <p ><strong>Job Number</strong></p>
                  <p ><strong>LPD No.</strong></p>
                  <p ><strong>Currency</strong></p>
                </div>
                <div className='me-4'>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                  <p>:</p>
                </div>
                <div>
                  <p >PI/{pi_info.JobNumber}</p>
                  <p>{new Date(pi_info.PiDate * 1000).toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka', year: '2-digit', month: '2-digit', day: '2-digit' }).replace(/\//g, '.')}</p>
                  <p>{pi_info.JobNumber}</p>
                  <p>{pi_info.LpdPoNo || 'N/A'}</p>
                  <p>{pi_info.Currency == 'BDT'? '৳' : '$'}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Item Name</th>
                    <th>Spec.</th>
                    <th>Measurement</th>
                    <th>Quantity</th>
                    <th>Sq. Mt.</th>
                    <th>Net <br/> Weight</th>
                    <th>Unit Price <br/> ($)</th>
                    <th>Addi Work <br/> Price ($)</th>
                    <th>Amount</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {pi_info.pi_lists.map((item, index) => {
                    let totalQuantity = pi_info.pi_lists.reduce((acc, curr) => acc + parseFloat(curr.Quantity), 0).toFixed(2);
                    let totalAmount = pi_info.pi_lists.reduce((acc, curr) => acc + (parseFloat(curr.Quantity) * parseFloat(curr.UnitPrice + curr.AddiPrice)), 0).toFixed(2);


                    return(
                      <>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ItemName}</td>
                          <td>{item.Specification}</td>
                          <td>{`${item.Length}x${item.Width}x${item.Height}`}</td>
                          <td>
                            <input
                              type="number"
                              style={{ width: '80px' }}
                              value={item.Quantity}
                              onChange={(e) => handleInputChange(index, 'Quantity', e.target.value)}
                            />
                          </td>
                          <td>{/* Calculation for Sq. Mt. if applicable */}</td>
                          <td>
                            <input
                              type="number"
                              style={{ width: '80px' }}
                              value={item.NetWeight}
                              onChange={(e) => handleInputChange(index, 'NetWeight', e.target.value)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              style={{ width: '80px' }}
                              value={item.UnitPrice}
                              onChange={(e) => handleInputChange(index, 'UnitPrice', e.target.value)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              style={{ width: '80px' }}
                              value={item.AddiPrice}
                              onChange={(e) => handleInputChange(index, 'AddiPrice', e.target.value)}
                            />
                          </td>
                          <td>${(item.Quantity * (item.UnitPrice + item.AddiPrice)).toFixed(2)}</td>
                          <td>
                            <input
                              type="text"
                              style={{ width: '80px' }}
                              value={item.Remarks || ''}
                              onChange={(e) => handleInputChange(index, 'Remarks', e.target.value)}
                            />
                          </td>
                        </tr>
                        {index === pi_info.pi_lists.length - 1 && (
                          <tr>
                            <td colSpan='4' style={{textAlign:'right'}}> <strong>Grand Total : </strong></td>
                            <td><strong>{totalQuantity}</strong></td>
                            <td colSpan='4'></td>
                            <td><strong>${totalAmount}</strong></td>
                            <td></td>
                          </tr>
                        )}
                      </>
                    )})}
                </tbody>
              </Table>
            </Row>
          </>
        )}
        {pi_info && (
        <Row className='d-flex justify-content-center mt-3'>
          <Col md='auto'>
            <p>Note: </p>
          </Col>
            <textarea rows='3' style={{width:'500px'}} value={pi_info.Note ? pi_info.Note : ''} onChange={handleNoteChange}/>
        </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const New_Group_Pi_Modal = ({ show, setShow, handleSave}) => {
  
  const [group_pi_no, set_group_pi_no] = useState('');
  const [party_address, set_party_address] = useState('');

  const onSave = () => {
    handleSave({group_pi_no, party_address});
    setShow(false);
  };

  useEffect(() => {
      set_group_pi_no('');
      set_party_address('');
  }, [show]);

  // console.log(number)
  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Group Pi</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <Row >
          <Col className='d-flex-col me-2'>
              <h5 style={{textAlign:'right', marginBottom:'40px', fontWeight:'bold'}}>Group PI No. : </h5>
              <h5 style={{textAlign:'right', marginBottom:'20px', fontWeight:'bold'}}>Party Address : </h5>
          </Col>
          <Col className='d-flex-col'>
              <Form.Control type="text" value={group_pi_no} onChange={(e) => set_group_pi_no(e.target.value)} style={{width:'300px',marginBottom:'30px'}}/>
              <textarea rows='4' value={party_address} onChange={(e) => set_party_address(e.target.value)} style={{width:'300px',marginBottom:'20px'}}/>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Remove_Group_Pi_Modal = ({ show, setShow, handleRemove, groupPi }) => {

  const onRemove = () => {
      handleRemove(groupPi.GroupPiId);
      setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title><strong>Remove Group PI</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <h5>Are you sure you want to remove {groupPi.GroupPiNo} of {groupPi?.PIs?.[0]?.PartyName} ?</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="danger" onClick={onRemove}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const PPExcelDateModal = ({ show, handleClose, handleSave }) => {
  const [date, setDate] = useState('');

  useEffect(() => {
    setDate('');
  },[]);

  const onChoose = () => {
    const pp_date_unix = Math.floor(new Date(date).getTime() / 1000);
    handleSave(pp_date_unix);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Choose P.P Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="scheduleDate">
            <Form.Label> <h6>Select a date:</h6></Form.Label>
            <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onChoose}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const convertNumberToWords = (amount) => {
  const units = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
      'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 
      'Seventeen', 'Eighteen', 'Nineteen'
  ];
  const tens = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 
      'Ninety'
  ];
  const thousands = ['', 'Thousand'];

  const getWords = (num) => {
      if (num === 0) return 'Zero';
      
      let words = '';
      
      if (num >= 1000) {
          words += getWords(Math.floor(num / 1000)) + ' ' + thousands[1] + ' ';
          num %= 1000;
      }
      
      if (num >= 100) {
          words += getWords(Math.floor(num / 100)) + ' Hundred ';
          num %= 100;
      }
      
      if (num >= 20) {
          words += tens[Math.floor(num / 10)] + ' ';
          num %= 10;
      }
      
      if (num > 0) {
          words += units[num] + ' ';
      }
      
      return words.trim();
  };
  
  const dollars = Math.floor(amount);
  const cents = Math.round((amount - dollars) * 100);

  const dollarWords = getWords(dollars);
  const centWords = getWords(cents);

  return `${dollarWords} Dollars${cents > 0 ? ' & ' + centWords + ' Cents' : ''}`;
};

// function formatNumberWithCommas(number) {
//   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }
function formatNumberWithCommas(number) {
  // Split the number into the integer and decimal parts
  const [integerPart, decimalPart] = number.toString().split('.');

  // Format the integer part with commas for crores, lakhs, and thousands
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);
  const formattedInteger = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? ',' : '') + lastThreeDigits;

  // Combine the formatted integer part with the decimal part if it exists
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}


function getAbbreviationForPi(name) {
  const words = name.split(' ').filter(word => word.length > 0); // Split and filter out empty strings
  const abbreviation = words.slice(0, 3) // Take the first 3 words
      .map(word => word.charAt(0)) // Take the first letter of each word
      .join(''); // Join them together
  return abbreviation;
}

export {handlePagination, renderPagination,renderTableData, renderTableData2, PartyTable, 
        renderAdvancedPagination, JobApprovalModal, ScheduleDateModal, Add_BM_Modal, Edit_PP_Qty_Modal, 
        PP_Date_Modal, BM_Small_Trash_Modal, Add_Finishing_Modal, Gate_Pass_Date_Modal,  Gate_Pass_Delete_Modal, Gate_Pass_Status_Modal,
         New_Gate_Pass_Modal, Edit_Bill_Modal, Edit_Pi_Modal, New_Group_Pi_Modal,convertNumberToWords, formatNumberWithCommas, getAbbreviationForPi,
         PPExcelDateModal, Remove_Group_Pi_Modal};
